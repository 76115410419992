import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Container, media } from '../ui';
import Layout from '../components/layout';
import Hero from '../components/Hero';
import { graphql } from 'gatsby';
import { get, trimStart, trimEnd } from 'lodash';
import { formatDate } from '../utils';
import YouTube from 'react-youtube';
import Seo from '../components/Seo';
import mediumZoom from 'medium-zoom';

const MainPictureWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  position: relative;
  width: 100%;
  padding-top: 42%;
`;

const MainPicture = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  background-position: center center;
  object-fit: cover;
`;

const Content = styled.div`
  line-height: 1.5;
  color: ${({ theme }) => theme.text};
  font-size: 1.25rem;
  padding-top: ${({ theme }) => theme.spacing()};
  padding-bottom: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
    padding-bottom: ${({ theme }) => theme.spacing(2)};

  `}

  ${media.desktop`
    padding-top: ${({ theme }) => theme.spacing(3.75)};
    padding-left: ${({ theme }) => theme.spacing(3.75)};
    padding-right: ${({ theme }) => theme.spacing(3.75)};

  `}
`;

const ArticleBody = styled.div`
  p {
    line-height: 1.5;
  }

  > p:first-child {
    margin-top: 0;
  }

  > p:last-child {
    margin-bottom: 0;
  }

  img {
    margin: ${({ theme }) => theme.spacing(2)} auto;
    max-height: 600px;
    max-width: 100%;
    border-radius: 4px;
  }

  a {
    color: ${({ theme }) => theme.primary};
  }

  strong {
    display: block;
    line-height: 1.5;
  }

  blockquote {
    box-shadow: inset 4px 0 0 rgba(0, 0, 0, 0.24);
    margin: ${({ theme }) => theme.spacing(2)} 0;
    padding: ${({ theme }) => theme.spacing()}
      ${({ theme }) => theme.spacing(2)};

    > p:first-child {
      margin-top: 0;
    }

    > p:last-child {
      margin-bottom: 0;
    }
  }
`;

const YouTubeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(2)};
  > * {
    width: 100%;
  }
`;

const Article = ({ data }) => {
  const article = get(data, 'datoCmsArticle');

  const getIdFromLink = url => trimEnd(trimStart(url)).split('?v=')[1];

  const options = {
    width: '100%',
    height: 360,
  };

  useEffect(() => {
    mediumZoom(document.querySelectorAll('#body img'));
    mediumZoom(document.querySelector('#cover'));
  }, []);

  return (
    <Layout variant="withAvocadoPattern">
      <Seo
        title={get(article, 'seo.title') || article.title}
        description={get(article, 'seo.description') || article.kicker}
        image={get(article, 'seo.image.url') || article.mainPicture.url}
        twitterCard={get(article, 'seo.twitterCard')}
      />
      <Hero
        type="personnalised"
        heroSubtitle={article.title}
        heroTitle={`Publié le ${formatDate(
          new Date(article.meta.publishedAt),
          'dd/MM/yyyy',
        )}`}
      />

      <Container
        variant="main"
        size="tight"
        paddingSize="none"
        background="white"
      >
        <Content>
          <strong>{article.kicker}</strong>
          <MainPictureWrapper>
            <MainPicture
              id="cover"
              src={article.mainPicture.url}
              alt={article.mainPicture.alt}
            />
          </MainPictureWrapper>
          <ArticleBody
            id="body"
            dangerouslySetInnerHTML={{
              __html: article.contentNode.childMarkdownRemark.html,
            }}
          />
        </Content>
        {get(article, 'youtube', []).map(({ youtubeLink }, index) => (
          <YouTubeWrapper key={index}>
            <YouTube videoId={getIdFromLink(youtubeLink)} opts={options} />
          </YouTubeWrapper>
        ))}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    datoCmsArticle(slug: { eq: $slug }) {
      title
      slug
      kicker
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      mainPicture {
        alt
        url
      }
      meta {
        publishedAt
      }
      youtube {
        title
        youtubeLink
      }
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }
  }
`;

export default Article;
